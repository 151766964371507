import { useStaticQuery, graphql } from 'gatsby';

const siteQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
        author {
          name
          url
        }
      }
    }
  }
`;

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(siteQuery);
  return site.siteMetadata;
};
